import React from "react"
import { Link } from "gatsby"

import CategoryEyebrow from "../CategoryEyebrow"

import ArrowIcon from "../../resources/assets/arrow.svg"

import styles from "./ImageBlock.module.css"
import { generatePostPath } from "../../globals/helpers"

const ImageBlock = ({
  size = "small",
  categories,
  featured_media,
  slug,
  title,
  external_link,
  og_image
}) => {
  const categoryPath = generatePostPath(categories)
  return (
    <div className={styles[`${size}ImageBlock`]}>
      {(featured_media?.localFile?.url || og_image) && (
        <Link
          className={styles.image}
          to={external_link ? external_link : `/${categoryPath}/${slug}`}
          target={external_link ? "_blank" : "same"}
        >
          <img
            src={og_image ? og_image : featured_media.localFile.url}
            alt={featured_media?.alt_text}
          />
        </Link>
      )}
      {categories && (
        <div className={styles.categories}>
          <CategoryEyebrow categories={categories} />
        </div>
      )}
      <strong>
        <Link
          to={external_link ? external_link : `/${categoryPath}/${slug}`}
          target={external_link ? "_blank" : "same"}
		  dangerouslySetInnerHTML={{ __html: title }}
        />
      </strong>
      <Link
        to={external_link ? external_link : `/${categoryPath}/${slug}`}
        target={external_link ? "_blank" : "same"}
        className={styles.readMore}
      >
        Read More <ArrowIcon />
      </Link>
    </div>
  )
}

export default ImageBlock
