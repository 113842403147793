import {
  CASE_STUDY_CATEGORY,
  CASE_STUDY_SLUG,
  WHITE_PAPER_CATEGORY,
  WHITE_PAPER_SLUG,
  NEWS_CATEGORY,
  NEWS_SLUG,
} from "./constants"

export const trimParagraphs = string => string?.replace(/(<([p/p]+)>)/gi, "").replace(/&nbsp;/g, " ")

export const generatePostPath = categories => {
  if (categories && categories.length) {
    if (categories.some(category => category.name === CASE_STUDY_CATEGORY)) {
      return CASE_STUDY_SLUG
    }

    if (categories.some(category => category.name === WHITE_PAPER_CATEGORY)) {
      return WHITE_PAPER_SLUG
    }

    if (categories.some(category => category.name === NEWS_CATEGORY)) {
      return NEWS_SLUG
    }
  }

  return "blog"
}
