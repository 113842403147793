import React from "react";

import styles from "./Heading.module.css";

const Heading = ({ children, type: HeadingType, level, text }) => {
	if (!HeadingType) {
		return <></>;
	}
	return (
		text ?
			<HeadingType className={styles[`level${level}`]} dangerouslySetInnerHTML={{ __html: text }}/>
		:
		<HeadingType className={styles[`level${level}`]}>
			{children}
		</HeadingType>
	)
};

export default Heading;