import React, { useState, useRef } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Container from "../components/Container";
import ImageBlock from "../components/ImageBlock";
import ModuleSpacing from "../components/ModuleSpacing";
import SEO from "../components/seo";

import styles from "./SearchPage.module.css";
import Heading from "../components/Heading";

const SearchPage = ({ data }) => {
	const allPosts = data.allWordpressPost.edges;
	const [searchTerm, setSearchTerm] = useState("");
	const searchRef = useRef(null);
	return (
	<>
		<Layout>
			<SEO
				title="Search"
			/>
			<Container>
				<ModuleSpacing>
					<div className={styles.header}>
						<Heading type="h4">Search Goodway Group</Heading>
						<form onSubmit={e => { e.preventDefault(); setSearchTerm(searchRef.current.value)}}>
							<input
								type="search"
								ref={searchRef}
							/>
							<input type="submit" value="Search" />
						</form>
					</div>
					{searchTerm !== "" && (
						<ul className={styles.postList}>
							{allPosts.filter(post => post.node.content.toLowerCase().includes(searchTerm.toLowerCase())).map((post, index) => (
								<ImageBlock
									key={index}
									external_link={post?.acf?.external_link}
									title={post.node.title}
									slug={post.node.slug}
									featured_media={post.featured_media}
									categories={post?.categories} 
								/>
							))}
							{allPosts.filter(post => post.node.content.includes(searchTerm)).length === 0 && <Heading type="h5">No results for {searchTerm}</Heading>}
						</ul>
					)}
				</ModuleSpacing>
			</Container>
		</Layout>
	</>
	)
};

export default SearchPage;

export const query = graphql`
  query {
    allWordpressPost {
      edges {
        node {
          title
          categories {
            name
            slug
          }
          featured_media {
            alt_text
            localFile {
              url
            }
          }
          slug
          excerpt
		  content
        }
      }
    }
  }
`
