import React from "react";

import Eyebrow from "../Eyebrow";

import blogIcon from "../../resources/assets/blog.svg";
import caseStudyIcon from "../../resources/assets/case-study.svg";
import downloadIcon from "../../resources/assets/download.svg";

import styles from "./CategoryEyebrow.module.css";

const CategoryEyebrow = ({ color = "Default", categories }) => {
	const Icon = categories[0].name === "White Paper"
		? downloadIcon
		: categories[0].name === "Case Study"
			? caseStudyIcon
			: blogIcon;
	return (
		<div className={`${styles.category} ${styles[`color${color}`]}`}>
			{Icon && <Icon />}<Eyebrow color="Yellow">{categories[0].name}</Eyebrow>
		</div>
	);
};

export default CategoryEyebrow;